@import "~styles/colors.module";
@import "~styles/sizes.module";

.bottomBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 6px 0px 11px;
}

.openButton {
  width: 83px;
  height: 17px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.openIcon {
  transition: transform 0.1s;
}

.voteLabel {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 140px;

  & > span:nth-child(1) {
    margin-bottom: 2px;
  }

  & > span:nth-child(2) {
    margin-left: 10px;
  }
}

.hashLink {
  text-decoration: none;
}

.voteButton {
  width: 137px;
  height: 28px;

  &:hover {
    opacity: 0.9;
  }
}

@media (max-width: $S_SCREEN_SIZE) {
  .openButton {
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
  }

  .openIcon {
    width: 22px;
    height: 22px;
    transition: transform 0.1s;
  }

  .voteLabel {
    justify-content: center;
    align-items: center;
    width: 80;
  }
}
