@import "~styles/colors.module";

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.sliderWrapper {
	height: 56px;
	width: 100%;
}

.slider {
	width: 100%;
	background-color: transparent !important;
}

.sliderHandle {
	transform: translateX(-1px);
}

.voteButton {
	width: 137px;
	margin-top: 21px;
	height: 28px;

	&:hover {
		opacity: 0.9;
	}
}
