@import "~styles/colors.module";
@import "~styles/sizes.module";

.infoContainer {
  width: 80%;
  margin-bottom: 13px;

  &.horizontal {
    display: flex;
    margin-bottom: 0px;
  }
}

.infoLabel {
  display: block;
  width: 100%;
  text-align: left;

  &.horizontal {
    width: 53%;
    margin-top: 23px;
  }
}

.valueContainer {
  width: 100%;
  min-height: 54px;
  border: 1px solid $LIGHT_GREY;
  border-radius: 50px;
  margin-top: 13px;

  & > span {
    display: block;
    margin: 18px 54px;
  }

  &.horizontal {
    width: 50%;
    margin-left: 21px;
    border-radius: 20px;

    & > span {
      margin: 15px 22px;
    }
  }
}

.textUrl {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $RED;
  }
}

@media (max-width: $S_SCREEN_SIZE) {
  .infoContainer {
    width: 95%;
  }

  .infoLabel {
    text-align: center;
  }
}
