$RED: #da2b3e;
$BLACK: #2f2f2f;
$WHITE: #ffffff;
$GREY: #888f9f;
$DARK_GREY: #a9a9a9;
$LIGHT_GREY: #c8c8c8;
$EXTRA_LIGHT_GREY: #fafafa;
$DARK_WHITE: #eeeeee;

:export {
  RED: $RED;
  BLACK: $BLACK;
  WHITE: $WHITE;
  GREY: $GREY;
  DARK_GREY: $DARK_GREY;
  LIGHT_GREY: $LIGHT_GREY;
  DARK_WHITE: $DARK_WHITE;
}
