.container {
  width: 100%;
  height: fit-content;
  margin: 10px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.hidden {
  height: 0px;
  margin: 0px;
}

.voteSection {
  max-width: 80%;
  width: 100%;
  margin-top: 45px;
}
