@import "~styles/colors.module";

.button {
  background: $RED;
  padding: 9px 30px;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    background: $WHITE;
    border: 1px solid $DARK_WHITE;
    cursor: not-allowed;
  }
}

.text {
  white-space: nowrap;
}
