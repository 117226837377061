@import "~styles/colors.module";
@import "~styles/sizes.module";

.header {
  margin: 63px 130px 0;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
}

.logoIcon {
  min-width: 102px;
  min-height: 30px;
}

.profileWrapper {
  display: flex;
}

.exitButtonWrapper {
  min-width: 78px;
  display: flex;
  align-items: "center";
  justify-content: center;
}

@media (max-width: $S_SCREEN_SIZE) {
  .exitButtonWrapper {
    min-width: 31px;
    display: flex;
    align-items: "center";
  }
}
