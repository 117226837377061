@import "~styles/colors.module";
@import "~styles/sizes.module";

.exitButton {
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.exitText {
  text-transform: uppercase;
  margin-left: 8px;
}

.exitIcon {
  min-width: 22px;
  min-height: 21px;
}

@media (max-width: $S_SCREEN_SIZE) {
  .exitText {
    display: none;
  }
}
