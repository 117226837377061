@import "~styles/sizes.module";
@import "~styles/colors.module";

.form {
  margin-top: 46px;
  border: 1px solid $DARK_WHITE;
  background: $WHITE;
  border-radius: 20px;
  padding: 44px 19px 39px;
}

.loginInput {
  margin-bottom: 20px;
  margin-top: 26px;
}

.authButtonWrapper {
  display: flex;
  justify-content: center;
}

.authButton {
  margin-top: 33px;
  width: 100%;
  max-width: 328px;
  min-height: 54px;

  &:hover {
    opacity: 0.9;
  }
}

.errorTextWrapper {
  display: block;
  min-height: 18px;
}

@media (max-width: $S_SCREEN_SIZE) {
  .form {
    margin-top: 30px;
    padding: 39px 19px;
  }
}
