@import "~styles/colors.module";

.text {
  font-family: "Golos";
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.medium {
  font-weight: 500;
}

.light {
  font-weight: 400;
}

.xlarge {
  font-size: 36px;
  line-height: 43px;
}

.large {
  font-size: 24px;
  line-height: 27px;
}

.middle {
  font-size: 15px;
  line-height: 18px;
}

.small {
  font-size: 14px;
  line-height: 17px;
}

.xsmall {
  font-size: 13px;
  line-height: 16px;
}

.red {
  color: $RED;
}

.black {
  color: $BLACK;
}

.white {
  color: $WHITE;
}

.grey {
  color: $GREY;
}

.darkGrey {
  color: $DARK_GREY;
}

.lightGrey {
  color: $LIGHT_GREY;
}
