.mainLayout {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  margin: 51px 0 30px;
  display: flex;
  justify-content: center;
}
