@import "~styles/colors.module";
@import "~styles/sizes.module";

.profile {
  display: flex;
  margin-right: 45px;
  align-items: center;
}

.profileText {
  text-transform: uppercase;
  margin-left: 8px;
}

.humanIcon {
  min-width: 17px;
  min-height: 21px;
}

@media (max-width: $S_SCREEN_SIZE) {
  .profile {
    margin-right: 15px;
    margin-left: 35px;
  }

  .profileText {
    font-size: 11px;
    margin-left: 5px;
  }
}
