@import "~styles/colors.module";

.container {
  width: 100%;
  border: 1px solid $DARK_WHITE;
  background: $WHITE;
  border-radius: 20px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.nameLabel {
  margin: 16px 25px;
}
