@import "~styles/colors.module";
@import "~styles/sizes.module";

.titleContainer {
  max-width: 886px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.votes {
  border: 1px solid $DARK_WHITE;
  background: $WHITE;
  height: 54px;
  padding: 0 22px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.votesText {
  margin-right: 19px;
}

@media (max-width: $L_SCREEN_SIZE) {
  .titleContainer {
    padding: 0 30px;
  }
}

@media (max-width: $S_SCREEN_SIZE) {
  .titleContainer {
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;
  }

  .votes {
    max-width: 173px;
    margin-top: 22px;
  }
}
