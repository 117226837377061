@import "~styles/colors.module";

@font-face {
  font-family: "Golos";
  src: url("./../assets/fonts/GolosText-Medium.woff2") format("woff2"),
    url("./../assets/fonts/GolosText-Medium.woff") format("woff"),
    url("./../assets/fonts/GolosText-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Golos Black";
  src: url("./../assets/fonts/GolosText-Black.woff2") format("woff2"),
    url("./../assets/fonts/GolosText-Black.woff") format("woff"),
    url("./../assets/fonts/GolosText-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Golos Regular";
  src: url("./../assets/fonts/GolosText-Regular.woff2") format("woff2"),
    url("./../assets/fonts/GolosText-Regular.woff") format("woff"),
    url("./../assets/fonts/GolosText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Golos Bold";
  src: url("./../assets/fonts/GolosText-Bold.woff2") format("woff2"),
    url("./../assets/fonts/GolosText-Bold.woff") format("woff"),
    url("./../assets/fonts/GolosText-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Golos DemiBold";
  src: url("./../assets/fonts/GolosText-DemiBold.woff2") format("woff2"),
    url("./../assets/fonts/GolosText-DemiBold.woff") format("woff"),
    url("./../assets/fonts/GolosText-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Golos", sans-serif;
  background: $EXTRA_LIGHT_GREY;
}
