@import "~styles/colors.module";
@import "~styles/sizes.module";

.container {
  width: 80%;
  margin-bottom: 13px;
}

.contentContainer {
  width: 100%;
  display: flex;
  margin-top: 13px;
  justify-content: space-between;
}

.photoContainer {
  width: 53%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;

  & img {
    width: 174px;
    height: 174px;
    border: 1px solid $LIGHT_GREY;
    border-radius: 20px;
    object-fit: contain;
  }
}

.photoLink {
  width: 174px;
  height: 174px;
}

.buttonsContainer {
  width: 42%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.link {
  width: 100%;
  max-width: 307px;
  min-width: 190px;
  height: 28px;
  background-color: $RED;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &.disabledLink {
    color: #2f2f2f;
    background: #ffffff;
    border: 1px solid #eeeeee;
    cursor: not-allowed;
  }
}

@media (max-width: $L_SCREEN_SIZE) {
  .contentContainer {
    flex-direction: column;
  }

  .photoContainer {
    justify-content: space-around;
    width: 100%;

    & > img {
      width: 200px;
      height: 200px;
    }
  }

  .buttonsContainer {
    align-self: center;
    margin-top: 15px;
    height: 75px;
  }
}

@media (max-width: $S_SCREEN_SIZE) {
  .blockLabel {
    text-align: center;
    width: 100%;
    display: block;
  }
}
