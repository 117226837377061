@import '~styles/colors.module';

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.input {
  width: 100%;
  border-radius: 100px;
  border: 1px solid $LIGHT_GREY;
  padding: 18px 32px;
  font-size: 15px;
  color: $LIGHT_GREY;
  outline: 0;
  outline-offset: 0;

  &::placeholder {
    color: $LIGHT_GREY;
  }
}

.inputError {
  border: 1px solid $RED;
}

.errorTextWrapper {
  min-height: 18px;
  margin-top: 13px;
}
