.authContainer {
  max-width: 539px;
  width: 539px;
  margin: 0 auto;
}

.authTitle {
  margin-bottom: 46px;
  display: block;
}

@media (max-width: 768px) {
  .authContainer {
    padding: 0 5%;
    max-width: none;
    margin: 0;
  }
}
